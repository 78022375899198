@use 'styles/colors' as *;
@use 'styles/spacing' as *;

.textDark {
  fill: $text-dark;
  stroke: $text-dark;
  // Defaults to no stroke, can be overwritten in svg file:
  stroke-width: 0px;
}

.textLight {
  fill: $text-light;
  stroke: $text-light;
  stroke-width: 0px;
}

.systemDark {
  fill: $system-dark;
  stroke: $system-dark;
  stroke-width: 0px;
}

.warning {
  fill: $warning;
  stroke: $warning;
  stroke-width: 0px;
}

.onWarning {
  fill: $on-warning;
  stroke: $on-warning;
  stroke-width: 0px;
}

.xs {
  width: $xs;
  height: $xs;
}

.s {
  width: $s;
  height: $s;
}

.m {
  width: $m;
  height: $m;
}

.l {
  width: $l;
  height: $l;
}

.xl {
  width: $xl;
  height: $xl;
}

.xxl {
  width: $xxl;
  height: $xxl;
}
