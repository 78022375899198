$body-font-size-mobile: 1rem;
$body-font-size-laptop: 1rem;
$body-line-height-factor: 1.5;

$base-unit: 0.5rem;

$xs: $base-unit;
$s: 2 * $base-unit;
$m: 3 * $base-unit;
$l: 5 * $base-unit;
$xl: 7 * $base-unit;
$xxl: 10 * $base-unit;

$sidebar-width: 30 * $base-unit;
$bottom-bar-height: $xxl;

$page-margin-small: $s;
$page-margin-medium: $l;
$page-margin-large: $xl;

$min-touch-target: 3rem; //Could be reduced to 2.75rem (44px) (https://www.w3.org/WAI/WCAG21/Understanding/target-size.html)

$empty-line: var(--empty-line);

$text-max-width: 60ch;
