@use 'styles/utils' as *;
@use 'styles/spacing' as *;

.container {
  @include stack($xl);
  align-items: center;
}

.inputs {
  @include stack($m);
  max-width: $text-max-width;
}