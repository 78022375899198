@use 'styles/colors' as *;
@use 'styles/spacing' as *;
@use 'styles/typography' as *;
@use 'styles/devices' as *;

.container {
  @include label;

  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  width: fit-content;
  font-weight: bold;
  color: $text-dark;

  &:not(.small) {
    min-width: 11rem;
    max-width: 16rem;
    padding: $m $l;
  }

  &.small {
    min-width: 6rem;
    max-width: 10rem;
    padding: $s $m;
  }

  &.disabled,
  &.loading {
    opacity: 0.3;
  }

  &:hover:not(.disabled, .loading),
  &:focus-visible:not(.disabled, .loading) {
    opacity: 0.8;
  }
}

.primary {
  background-color: $accent;
}

.secondary {
  background-color: transparent;

  @include touch-device {
    $border-width: 2px;
    border: $border-width solid $text-dark;
    padding: calc($m - $border-width) calc($l - $border-width);
  }

  @include mouse-device {
    &:hover:not(.disabled, .loading),
    &:focus-visible:not(.disabled, .loading) {
      opacity: unset;
      background-color: $system-light;
    }
  }
}

.destructive {
  background-color: $warning;
  color: $on-warning;
}

.loadingIndicatorContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

// Uses visibility: hidden so that button size remains the same:
.hidden {
  visibility: hidden;
}
