$breakpoint-tablet: 600px;
$breakpoint-laptop: 1024px;
$breakpoint-desktop: 1600px;

// Exports to js:
:export {
  breakpoint-tablet: $breakpoint-tablet;
  breakpoint-laptop: $breakpoint-laptop;
  breakpoint-desktop: $breakpoint-desktop;
}

@mixin mobile {
  @media (max-width: calc($breakpoint-tablet - 1px)) and (hover: none) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-tablet) and (max-width: calc($breakpoint-laptop - 1px)) and (hover: none) {
    @content;
  }
}

@mixin touch-device {
  @media (max-width: calc($breakpoint-laptop - 1px)) and (hover: none) {
    @content;
  }
}

@mixin mouse-device {
  @media (hover: hover), (min-width: $breakpoint-laptop) {
    @content;
  }
}

@mixin tablet-or-mouse-device {
  @include tablet {
    @content;
  }
  @include mouse-device {
    @content;
  }
}
