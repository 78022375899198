$primary: #29a7f1;
$background: #f5f5f5;
$text-dark: #13146c;
$text-light: #ffffff;
$surface: #ffffff;
$accent: #ffc759;
$warning: #d72626;
$on-warning: $background;
$status-green: #6ec63a;
$status-orange: #ffc700; // TODO: differentiate from accent color?
$status-red: #c63a3a;
$system-dark: #c6c6c6;
$system-light: #f0f0f0; //TODO: make darker?
$overlay: #000000a3;
