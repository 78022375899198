@use 'styles/utils' as *;
@use 'styles/spacing' as *;
@use 'styles/forms' as *;
@use 'styles/colors' as *;
@use 'styles/typography' as *;
@use 'styles/devices' as *;

.container {
  @include stack($xs);

  @include tablet-or-mouse-device {
    &.hasLabelLeft {
      @include row($s);
      justify-content: space-between;
      align-items: center;
    }
  }

  &.disabled {
    opacity: 0.4;
  }
}

.inputContainer {
  position: relative;

  @include mobile {
    width: 100%;
  }
}

.rightContainer {
  position: absolute;
  right: $input-padding;
  top: 50%;
  transform: translateY(-50%);
}

.input {
  width: 100%;
  height: $l;
  border: $input-border;
  padding: 0 $input-padding;

  @include mouse-device {
    &[type='text'],
    &[type='email'],
    &[type='tel'],
    &:not([type]) {
      width: 20rem;
    }
  }

  &[type='number'] {
    width: 6rem;
  }

  &.hasRightElement {
    padding-right: $min-touch-target;
  }

  &.upperCase {
    text-transform: 'uppercase';
  }
}

.caption {
  @include caption;
  text-decoration: underline;
}
