@use './spacing' as *;

$scale-factor: 1.33;

@mixin body {
  font-size: var(--body-size);
  line-height: $body-line-height-factor;
}

@mixin label {
  font-size: var(--body-size);
  line-height: 1;
}

@mixin h3 {
  font-size: calc(var(--body-size) * $scale-factor);
  line-height: 1.4;
  font-weight: bold;
}

@mixin h2 {
  font-size: calc(var(--body-size) * $scale-factor * $scale-factor);
  line-height: 1.2;
  font-weight: bold;
}

@mixin h1 {
  font-size: calc(var(--body-size) * $scale-factor * $scale-factor * $scale-factor);
  line-height: 1.1;
  font-weight: bold;
}

@mixin overline {
  font-size: calc(var(--body-size) / $scale-factor);
  line-height: 1;
  text-transform: uppercase;
}

@mixin caption {
  font-size: calc(var(--body-size) / $scale-factor);
  line-height: 1;
}
