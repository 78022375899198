@keyframes spin {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 360deg;
  }
}

.container {
  width: fit-content;
  line-height: 0; // Prevents whitespace below icon
  animation: spin 3.5s infinite linear;
}
