@use './spacing' as *;
@use './devices' as *;

@mixin stack($gap: 0px) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin row($gap: 0px) {
  display: flex;
  flex-direction: row;
  gap: $gap;
}

@mixin horizontal-page-padding {
  padding-left: $s;
  padding-right: $s;

  // TODO: test on tablet:
  @include tablet {
    padding-left: $m;
    padding-right: $m;
  }

  @include mouse-device {
    padding-left: $l;
    padding-right: $l;
  }
}

@mixin vertical-page-padding {
  padding-top: $s;
  padding-bottom: $xl + $bottom-bar-height;

  // TODO: test on tablet:
  @include tablet {
    padding-top: $l;
  }

  @include mouse-device {
    padding-top: $l;
    padding-bottom: $xxl;
  }
}

@mixin truncate-text($lines: 1) {
  @if $lines == 1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  } @else {
    height: fit-content;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@mixin hide-scrollbar {
  -ms-overflow-style: none; // Internet Explorer 10
  scrollbar-width: none; // Firefox

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

@mixin full-height-without-navbar {
  height: calc(100vh - $bottom-bar-height);
}

// Hides the default radio icon but keeps it keyboard-selectable:
@mixin hide-radio {
  position: fixed;
  width: 0;
  opacity: 0;
}

// Temporarily used instead of native inset, for better browser support:
@mixin inset($space) {
  top: $space;
  right: $space;
  bottom: $space;
  left: $space;
}

@mixin break-out {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
